import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import FormButton from "../../../../components/Button"

import { clearAllNotifications } from "../../../../redux/reducers/notifications/actionCreators"
import { selectCurrentUserId } from "../../../../redux/reducers/authentication/selectors"
import { selectNotifications } from "../../../../redux/reducers/notifications/selectors"

const StyledDate = styled.div`
  align-items: center;
  color: #90A3BF;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 21px;
  padding: 18px 0;
`

const StyledText = styled.div`
  align-items: center;
  color: #1A202C;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  padding-left: 24px;

  :after {
    background-color: #3563E9;
    border-radius: 50px;
    content: "";
    display: inline-block;
    height: 8px;
    left: 4px;
    position: absolute;
    top: 17px;
    width: 8px;
  }

  :before {
    border-radius: 50px;
    border: 8px solid rgba(53, 99, 233, 0.3);
    content: "";
    display: inline-block;
    left:0;
    position: absolute;
    top: 13px;
  }
`;

const HeaderNotificationsMenu = () => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)

  const notifications = useSelector(selectNotifications) || {}
  const userId = useSelector(selectCurrentUserId);

  const onClose = () => {
    ref.current?.classList.remove("show")
  }

  const clearAll = () => {
    dispatch(clearAllNotifications(userId))
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold w-600px'
      data-kt-menu='true'
      ref={ref}
    >
      <h2 className="p-5">Notifications</h2>
      <div className='scroll-y mh-325px border-top border-bottom'>
        {Object.keys(notifications).map((date, index) => (
          <>
            <div key={`date-${index}`} className="p-10 border-bottom">
              <StyledDate>{date}</StyledDate>
              {notifications[date].map((notification: any, i: number) => (
                <StyledText key={notification?.id} className='py-3'>{notification?.text}</StyledText>
              ))}
            </div>
          </>
        ))}
      </div>
      <div className='d-flex justify-content-end p-5'>
        <FormButton title={`Close`} onClick={onClose}></FormButton>
        <FormButton variant="primary" onClick={clearAll} title={`Clear`}></FormButton>
      </div>
    </div >
  )
}

export { HeaderNotificationsMenu }
