import axios from 'axios';
import get from 'lodash/get';

import constants from '../constants';

export const errorMessage = (error: {request?: any; message?: any}) => {
  let msg = '';
  if (error.request) {
    if (error.message === 'Network Error') {
      msg = 'No internet connection';
    } else if (error.request.status === 500 || error.request.status === 404) {
      msg = 'Server Error: We\'re sorry, but something went wrong. Try again';
    } else {
      const code: number = error.request.status;
      msg = get(constants, `HTTPStatusCode.${code}`) || '';
    }
  } else {
    msg = error.message;
  }
  return msg;
};

export const toaster = (payload: {result: {message: any; error: any}}) => {
  const success = payload?.result?.message;
  const error = payload?.result?.error;
  let msg;
  let type = 'default';
  if (success) {
    msg = success;
    type = 'success';
  } else if (error) {
    msg = error;
    type = 'error';
  }
  if (msg && msg?.toLowerCase() !== 'unauthorized') {
    return {
      msg,
      a: {
        position: 'bottom-right',
        type,
      },
    };
  }
  return null;
};

const configureClients = ({ baseURL, apiURL, googleMapsURL }: any) => ({
  default: {
    client: axios.create({
      baseURL,
      responseType: 'json',
      headers: {
        common: {
          Accept: 'application/json',
        },
        post: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    }),
  },
  api: {
    client: axios.create({
      baseURL: apiURL,
      responseType: 'json',
      headers: {
        common: {
          Accept: 'application/json',
        },
        post: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    }),
  },
  googleMaps: {
    client: axios.create({
      baseURL: googleMapsURL,
      responseType: 'json',
      headers: {
        common: {
          Accept: 'application/json',
        },
        post: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    }),
  },
});

export default configureClients(constants);
