import { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { Form } from 'react-bootstrap';
import { format, isValid, parse } from 'date-fns';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';
import fr from 'date-fns/locale/fr';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import 'react-day-picker/dist/style.css';

const DialogSheet = styled.div`
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 4px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px -1px rgba(0, 0, 0, 0.09);
  z-index: 99;
`;

const StyledButton = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0066FF;

  i {
    font-size: 32px;
  }
`;

const Wrapper = styled.div`
  position: relative;

  input {
    background: #FFFFFF;
    border-radius: 7.68293px;
    border: 1px solid rgba(86, 103, 137, 0.26);
    color: #84878D;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    line-height: 21px;
  }
`;

function DatePicker({ className, onDateChange, value, disabledFromDate, placeholder, isInvalid, onBlur, label, errorMessage }) {
  const [selected, setSelected] = useState();
  const [inputValue, setInputValue] = useState('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, { placement: 'bottom-start' });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
    onDateChange(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());
    if (isValid(date)) {
      setSelected(date);
    } else {
      setSelected(undefined);
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date) => {
    if (!date) return;
    const selectedDate = format(date, 'y-MM-dd');
    onDateChange(selectedDate);
    setSelected(date);
    if (date) {
      setInputValue(selectedDate);
      closePopper();
    } else {
      setInputValue('');
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (value) {
      setSelected(new Date(value));
    }
  }, []);

  return (
    <>
      <Form.Group
        controlId="date"
        className={className}
      >
        {label && (
          <Form.Label>
            {label}
          </Form.Label>
        )}
        <Wrapper ref={popperRef}>
          <Form.Control
            onChange={handleInputChange}
            placeholder={placeholder}
            type="text"
            value={inputValue}
            name="date"
            isInvalid={isInvalid}
            onBlur={onBlur}
            readOnly
            onClick={handleButtonClick}
          />
          <StyledButton
            ref={buttonRef}
            type="button"
            aria-label="Pick a date"
            onClick={handleButtonClick}
          >
            <Icofont icon="calendar" />
          </StyledButton>
        </Wrapper>
        {errorMessage && (
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
        >
          <DialogSheet
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
              fromDate={disabledFromDate}
              locale={fr}
            />
          </DialogSheet>
        </FocusTrap>
      )}
    </>
  );
}

DatePicker.propTypes = {
  onDateChange: PropTypes.func,
  value: PropTypes.string,
  disabledFromDate: PropTypes.shape({}),
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

DatePicker.defaultProps = {
  onDateChange: () => { },
  value: '',
  disabledFromDate: {},
  placeholder: null,
  isInvalid: false,
  onBlur: () => { },
  label: null,
  errorMessage: null,
  className: null,
};

export default DatePicker;
