import { setUser } from '../user/actionCreators';
import { LOGIN, LOGOUT, REFRESH_TOKEN } from './actions';

export const login = (data) => ({
  callback: ({ dispatch }, payload) => dispatch(setUser(payload)),
  client: 'api',
  request: { data, method: 'post', url: '/users/sign_in' },
  type: LOGIN,
});

export const logout = () => ({
  client: 'api',
  request: { method: 'delete', url: '/users/sign_out' },
  type: LOGOUT,
  callback: ({ dispatch }) => dispatch({ type: LOGOUT }),
});

export const refreshToken = (payload) => ({
  type: REFRESH_TOKEN,
  payload,
});
