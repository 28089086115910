import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import { KTSVG } from '../../../helpers'
import {useLayout} from '../../core'
import styled from 'styled-components'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: React.ReactNode
}

const Item = styled.div`
  &&&{
    .show {
      margin-bottom: 8px;
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .menu-icon {
    svg {
      height: 24px !important;
      width: 24px !important;
    }
  }
`;

const StyledTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #90A3BF;
`;

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = pathname === '/incoming-mail' || pathname === '/outgoing-mail'
  const {config} = useLayout()
  const {aside} = config

  return (
    <Item
      className={clsx('menu-item', { 'show': isActive }, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <StyledTitle className='menu-title'>{title}</StyledTitle>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion')}>
        {children}
      </div>
    </Item>
  )
}

export {AsideMenuItemWithSub}
