import { ACTIVITIES, COURIERS_WITHOUT_ASSIGNMENT, COURIERS_WITHOUT_REASSIGNMENT, STATS } from './actions';

export const getStats = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/dashboard', params: { ...params, show: 'stats' } },
  type: STATS,
});

export const getActivities = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/dashboard', params: { ...params, show: 'activities' } },
  type: ACTIVITIES,
});

export const getCouriersWithoutAssignment = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/dashboard', params: { ...params, show: 'couriers_without_assignment' } },
  type: COURIERS_WITHOUT_ASSIGNMENT,
});

export const getCouriersWithoutReassignment = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/dashboard', params: { ...params, show: 'couriers_without_reassignment' } },
  type: COURIERS_WITHOUT_REASSIGNMENT,
});
