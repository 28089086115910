import { createReducer } from '../../utility';
import { ERROR, LOADED, LOADING } from '../../middleware/actions';
import { LOGOUT } from '../authentication/actions';
import { COURRIERS_DEPARTURE, CREATE_COURRIERS_DEPARTURE, DELETE_COURRIERS_DEPARTURE, UPDATE_COURRIERS_DEPARTURE } from './actions';

const initialState = {
  createLoader: false,
  data: {},
  deleteLoader: false,
  error: null,
  loader: false,
  updateLoader: false,
  pagination: {
    page: 1,
    per_page: 10,
    total_objects: 0,
    total_pages: 0,
    links: {
      first: '',
      last: '',
      next: '',
    },
  },
};

const reducers = {
  [COURRIERS_DEPARTURE + LOADING]() {
    return {
      ...initialState,
      loader: true,
    };
  },
  [COURRIERS_DEPARTURE + LOADED](state, payload) {
    const data = payload?.result?.courrier_departs?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      loader: false,
      error: null,
      data: { ...state.data, ...data },
      pagination: {
        ...state.pagination,
        ...payload?.result?.meta.pagination,
        page: payload?.originalPayload?.page,
      },
    };
  },
  [COURRIERS_DEPARTURE + ERROR](state, payload) {
    return {
      ...state,
      loader: false,
      error: payload.result,
    };
  },

  [CREATE_COURRIERS_DEPARTURE + LOADING](state) {
    return {
      ...state,
      createLoader: true,
    };
  },
  [CREATE_COURRIERS_DEPARTURE + LOADED](state, payload) {
    return {
      ...state,
      createLoader: false,
      error: null,
      data: { ...state.data, [payload?.result?.id]: payload.result },
    };
  },
  [CREATE_COURRIERS_DEPARTURE + ERROR](state, payload) {
    return {
      ...state,
      createLoader: false,
      error: payload.result,
    };
  },

  [UPDATE_COURRIERS_DEPARTURE + LOADING](state) {
    return {
      ...state,
      updateLoader: true,
    };
  },
  [UPDATE_COURRIERS_DEPARTURE + LOADED](state, payload) {
    return {
      ...state,
      updateLoader: false,
      error: null,
      data: { ...state.data, [payload?.result?.id]: payload.result },
    };
  },
  [UPDATE_COURRIERS_DEPARTURE + ERROR](state, payload) {
    return {
      ...state,
      updateLoader: false,
      error: payload.result,
    };
  },

  [DELETE_COURRIERS_DEPARTURE + LOADING](state) {
    return {
      ...state,
      deleteLoader: true,
      error: null,
    };
  },
  [DELETE_COURRIERS_DEPARTURE + LOADED](state, payload) {
    const newData = state.data;
    delete newData[payload?.originalPayload?.id];
    return {
      ...state,
      data: newData,
      deleteLoader: false,
      error: null,
    };
  },
  [DELETE_COURRIERS_DEPARTURE + ERROR](state, payload) {
    return {
      ...state,
      deleteLoader: false,
      error: payload.result,
    };
  },

  // Reset reducers
  [LOGOUT]() {
    return initialState;
  },
  [LOGOUT + LOADED]() {
    return initialState;
  },
};

export default createReducer(reducers, initialState);
