import { createSelector } from 'reselect';
import { REDUCERS_NAME } from '../../constants';

const reducerName = REDUCERS_NAME.AUTHENTICATION;

export const loginBase = (state) => state[reducerName] || {};
export const selectAuthToken = createSelector(loginBase, (data) => data?.token || null);
export const selectCurrentUserId = createSelector(loginBase, (data) => data?.currentUserId || 0);
export const selectIsLoggedIn = createSelector(loginBase, (data) => data?.isLoggedIn);
export const selectLoginData = createSelector(loginBase, (data) => data?.data || {});
export const selectLoginError = createSelector(loginBase, (data) => data?.error || null);
export const selectLoginIsLoading = createSelector(loginBase, (data) => data?.loader || false);
export const selectRefreshToken = createSelector(loginBase, (data) => data?.refreshToken || null);
export const selectTokenExpireAt = createSelector(loginBase, (data) => data?.tokenExpireAt || null);
