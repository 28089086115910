import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  couriersWithoutAssignmentData,
  couriersWithoutAssignmentLoader,
  couriersWithoutAssignmentPagination,
} from '../../redux/reducers/dashboard/selectors';
import { getCouriersWithoutAssignment } from '../../redux/reducers/dashboard/actionCreators';
import { WORKFLOW_STATE_KEY } from '../../constants';

const StyledDataTable = styled(DataTable)`
  border-radius: 10px;
  border: 1px solid rgba(195, 212, 233, 0.5);
  margin-top: 16px;

  .rdt_TableCell, .rdt_TableCol {
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    font-weight: 700;
  }

  .rdt_TableHeadRow {
    background-color: #81CC4D;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    color: #fff;
  }

  .rdt_Pagination {
    border: unset;
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }
`;

const StyledHeading = styled.h3`
  color: #2D2D2D;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-top: 16px;
  padding-left: 24px;
  position: relative;

  :after {
    background-color: #81CC4D;
    border-radius: 50px;
    content: "";
    display: inline-block;
    height: 8px;
    left: 4px;
    position: absolute;
    top: 14px;
    width: 8px;
  }

  :before {
    border-radius: 50px;
    border: 8px solid #D9F0CA;
    content: "";
    display: inline-block;
    left:0;
    position: absolute;
    top: 10px;
  }
`;

function CouriersWithoutAssignmentTable({ startPeriod, endPeriod }) {
  const dispatch = useDispatch();

  const activityPagination = useSelector(couriersWithoutAssignmentPagination);
  const isLoading = useSelector(couriersWithoutAssignmentLoader);
  const tableData = Object.values((useSelector(couriersWithoutAssignmentData)));

  const stringWithTooltip = (val) => (
    <OverlayTrigger overlay={(<Tooltip>{val}</Tooltip>)}>
      <div>{val}</div>
    </OverlayTrigger>
  );

  const columns = [
    {
      name: 'Sequence',
      selector: (row) => row.sequence,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Date Correspondance',
      selector: (row) => row.date_correspondance,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Correspondant',
      selector: (row) => stringWithTooltip(row.correspondant_name),
      sortable: true,
      width: '160px',
    },
    {
      name: 'Date Edition',
      selector: (row) => row.date_edition,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Objet',
      selector: (row) => stringWithTooltip(row.objet),
      sortable: true,
      width: 'unset',
    },
    {
      name: 'Statut',
      selector: (row) => WORKFLOW_STATE_KEY[row.workflow_state?.toLowerCase()],
      sortable: true,
      width: 'unset',
    },
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(getCouriersWithoutAssignment({ limit: newPerPage, page, start_period: startPeriod, end_period: endPeriod }));
  };

  const handlePageChange = (page) => {
    dispatch(getCouriersWithoutAssignment({ limit: activityPagination?.per_page, page, start_period: startPeriod, end_period: endPeriod }));
  };

  useEffect(() => {
    dispatch(getCouriersWithoutAssignment({ start_period: startPeriod, end_period: endPeriod }));
  }, []);

  useEffect(() => {
    dispatch(getCouriersWithoutAssignment({ start_period: startPeriod, end_period: endPeriod }));
  }, [startPeriod, endPeriod]);

  return (
    <>
      <StyledHeading>Courriers reçus sans imputation</StyledHeading>
      <StyledDataTable
        columns={isLoading ? [] : columns}
        data={isLoading ? [] : tableData}
        progressPending={isLoading}
        pagination
        paginationServer
        paginationTotalRows={activityPagination?.total_objects || 0}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        dense
        noDataComponent={<p style={{ margin: '0', padding: 24 }}>Il n&apos;y a aucun enregistrement à afficher</p>}
      />

    </>
  );
}

CouriersWithoutAssignmentTable.propTypes = {
  startPeriod: PropTypes.string,
  endPeriod: PropTypes.string,
};

CouriersWithoutAssignmentTable.defaultProps = {
  startPeriod: null,
  endPeriod: null,
};

export default CouriersWithoutAssignmentTable;
