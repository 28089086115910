import { createReducer } from '../../utility';
import { ERROR, LOADED, LOADING } from '../../middleware/actions';
import { LOGOUT } from '../authentication/actions';
import { MAIL_CLASSIFICATION_DEPARTURE, MAIL_CLASSIFICATION_ARRIVAL } from './actions';

const initialState = {
  arrival: {
    data: {},
    error: null,
    loader: false,
    pagination: {
      page: 1,
      per_page: 10,
      total_objects: 0,
      total_pages: 0,
      links: { first: '', last: '', next: '' },
    },
  },
  departs: {
    data: {},
    error: null,
    loader: false,
    pagination: {
      page: 1,
      per_page: 10,
      total_objects: 0,
      total_pages: 0,
      links: { first: '', last: '', next: '' },
    },
  },
};

const reducers = {
  [MAIL_CLASSIFICATION_DEPARTURE + LOADING]() {
    return {
      ...initialState,
      departs: {
        ...initialState.departs,
        loader: true,
      },
    };
  },
  [MAIL_CLASSIFICATION_DEPARTURE + LOADED](state, payload) {
    const data = payload?.result?.courrier_departs?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      departs: {
        ...state.departs,
        loader: false,
        error: null,
        data: { ...state.departs.data, ...data },
        pagination: {
          ...state.departs.pagination,
          ...payload?.result?.meta.pagination,
          page: payload?.originalPayload?.page,
        },
      },
    };
  },
  [MAIL_CLASSIFICATION_DEPARTURE + ERROR](state, payload) {
    return {
      ...state,
      departs: {
        ...state.departs,
        loader: false,
        error: payload.result,
      },
    };
  },

  [MAIL_CLASSIFICATION_ARRIVAL + LOADING]() {
    return {
      ...initialState,
      arrival: {
        ...initialState.arrival,
        loader: true,
      },
    };
  },
  [MAIL_CLASSIFICATION_ARRIVAL + LOADED](state, payload) {
    const data = payload?.result?.courrier_arrivees?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      arrival: {
        ...state.arrival,
        loader: false,
        error: null,
        data: { ...state.arrival.data, ...data },
        pagination: {
          ...state.arrival.pagination,
          ...payload?.result?.meta.pagination,
          page: payload?.originalPayload?.page,
        },
      },
    };
  },
  [MAIL_CLASSIFICATION_ARRIVAL + ERROR](state, payload) {
    return {
      ...state,
      arrival: {
        ...state.arrival,
        loader: false,
        error: payload.result,
      },
    };
  },

  // Reset reducers
  [LOGOUT]() {
    return initialState;
  },
  [LOGOUT + LOADED]() {
    return initialState;
  },
};

export default createReducer(reducers, initialState);
