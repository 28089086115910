import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { useMemo } from 'react';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import clients from '../config/clients';
import reducerRegistry from './ReducerRegistry';
import createApiClient from './middleware/apiClients';

import actionsReducer from './reducers/actions';
import authReducer from './reducers/authentication';
import correspondentsReducer from './reducers/correspondents';
import courriersArriveeReducer from './reducers/courriersArrivee';
import courriersDepartureReducer from './reducers/courriersDeparture';
import dashboardReducer from './reducers/dashboard';
import mailClassificationReducer from './reducers/mailClassification';
import notificationsReducer from './reducers/notifications';
import userReducer from './reducers/user';

import { REDUCERS_NAME } from './constants';

export const saveAuthFilter = createFilter(REDUCERS_NAME.AUTHENTICATION, [
  'currentUserId',
  'isLoggedIn',
  'token',
  'refreshToken',
  'tokenExpireAt',
]);
export const loadAuthFilter = createFilter(
  REDUCERS_NAME.AUTHENTICATION,
  undefined,
  ['currentUserId', 'isLoggedIn', 'token', 'refreshToken', 'tokenExpireAt'],
);
export const saveUserFilter = createFilter(REDUCERS_NAME.USER, ['all.data']);
export const loadUserFilter = createFilter(REDUCERS_NAME.USER, undefined, ['all.data']);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [REDUCERS_NAME.AUTHENTICATION, REDUCERS_NAME.USER],
  transforms: [saveAuthFilter, loadAuthFilter, saveUserFilter, loadUserFilter],
};

// Add default reducers
reducerRegistry.register(REDUCERS_NAME.ACTIONS, actionsReducer);
reducerRegistry.register(REDUCERS_NAME.AUTHENTICATION, authReducer);
reducerRegistry.register(REDUCERS_NAME.CORRESPONDENTS, correspondentsReducer);
reducerRegistry.register(REDUCERS_NAME.COURRIERS_ARRIVEE, courriersArriveeReducer);
reducerRegistry.register(REDUCERS_NAME.COURRIERS_DEPARTURE, courriersDepartureReducer);
reducerRegistry.register(REDUCERS_NAME.DASHBOARD, dashboardReducer);
reducerRegistry.register(REDUCERS_NAME.MAIL_CLASSIFICATION, mailClassificationReducer);
reducerRegistry.register(REDUCERS_NAME.NOTIFICATIONS, notificationsReducer);
reducerRegistry.register(REDUCERS_NAME.USER, userReducer);

const reducers = combineReducers(reducerRegistry.getReducers());
const persistedReducer = persistReducer(persistConfig, reducers);

function makeStore(preloadedState) {
  return configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk, createApiClient(clients)],
    preloadedState,
    reducer: persistedReducer,
  });
}

let store;

export const initializeStore = (preloadedState) => {
  let newStore = store || makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    newStore = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return newStore;
  // Create the store once in the client
  if (!store) store = newStore;

  return newStore;
};

export function useStore(initialState) {
  const newStore = useMemo(() => initializeStore(initialState), [initialState]);
  return newStore;
}
