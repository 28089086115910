import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
  && {
    background-color: #F6F7F9;
    border-radius: 19px;
    border: 1px solid rgba(195, 212, 233, 0.5) !important;
    color: #90A3BF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 56px;
    line-height: 24px;
    margin-left: 16px;
    width: 209px;

    ${({ variant }) => variant === 'primary' && css`
      background-color: #7AC943;
      border: unset !important;
      color: #FFFFFF;

      &&& {
        &:hover, &:active, &:focus {
          background-color: #7AC943 !important;
        }
      }
    `}

    ${({ variant }) => variant === 'danger' && css`
      background-color: #FF4423;
      border: unset !important;
      color: #FFFFFF;

      &&& {
        &:hover, &:active, &:focus {
          background-color: #FF4423 !important;
        }
      }
    `}
  }
`;

function FormButton({
  icon,
  isLoading,
  onClick,
  size,
  title,
  variant,
}) {
  const text = (
    <>
      {icon && <i className={`fa fa-${icon}`} />}
      {title && <span>{title}</span>}
    </>
  );
  return (
    <StyledButton
      disabled={isLoading}
      onClick={onClick}
      size={size}
      variant={variant}
    >
      {(variant !== 'light' && isLoading) ? (
        <Spinner animation="border" />
      ) : text}
    </StyledButton>
  );
}

FormButton.propTypes = {
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
};

FormButton.defaultProps = {
  icon: null,
  isLoading: false,
  onClick: () => { },
  size: 'sm',
  title: null,
  variant: 'light',
};

export default FormButton;
