import isEmpty from 'lodash/isEmpty';

import { DateTime } from 'luxon';
import { createReducer } from '../../utility';
import { ERROR, LOADED, LOADING } from '../../middleware/actions';
import { LOGIN, LOGOUT, REFRESH_TOKEN } from './actions';

const initialState = {
  currentUserId: null,
  data: null,
  error: null,
  isLoggedIn: false,
  loader: false,
  refreshToken: null,
  token: null,
  tokenExpireAt: null,
};

const reducers = {
  [LOGIN + LOADING]() {
    return {
      ...initialState,
      loader: true,
    };
  },

  [LOGIN + LOADED](state, payload) {
    return {
      ...state,
      currentUserId: payload?.result?.user?.id,
      isLoggedIn: !isEmpty(payload?.result?.user),
      loader: false,
      refreshToken: payload?.result?.refresh_token,
      token: payload?.result?.token,
      tokenExpireAt: DateTime.fromSeconds(parseInt(payload?.result?.token_expire_at, 10)),
    };
  },

  [LOGIN + ERROR](state, payload) {
    return {
      ...initialState,
      error: payload,
      isLoggedIn: false,
    };
  },

  [REFRESH_TOKEN](state, payload) {
    return {
      ...state,
      isLoggedIn: !isEmpty(payload),
      loader: false,
      refreshToken: payload?.refresh_token,
      token_expire_at: parseFloat(payload?.token_expire_at),
      token: payload?.token,
      tokenExpireAt: DateTime.fromSeconds(parseInt(payload?.token_expire_at, 10)),
    };
  },

  [LOGOUT]() {
    return initialState;
  },

  [LOGOUT + LOADED]() {
    return initialState;
  },
};

export default createReducer(reducers, initialState);
