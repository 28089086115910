import { USER, USERS, USERS_ROLE } from './actions';

export const setUser = (data) => ({
  type: USER,
  payload: data,
});

export const getUsers = () => ({
  client: 'api',
  request: { method: 'get', url: '/users' },
  type: USERS,
});

export const getUserById = (id) => ({
  type: USER,
  client: 'api',
  request: { method: 'get', url: `/users/${id}` },
});

export const getUsersByRole = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/users', params },
  type: USERS_ROLE,
});
