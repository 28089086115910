import omit from 'lodash/omit';

import { createReducer } from '../../utility';
import { ERROR, LOADED, LOADING, BASIC_STATE } from '../../middleware/actions';
import { LOGOUT } from '../authentication/actions';
import { USER, USERS, USERS_ROLE } from './actions';

const initialState = {
  all: BASIC_STATE,
  byRole: BASIC_STATE,
};

const reducers = {
  [USER](state, payload) {
    return {
      ...state,
      all: {
        ...state.all,
        data: {
          ...state.all.data,
          [payload?.result?.user?.id]: omit(payload?.result?.user, ['token', 'token_expire_at', 'refresh_token']),
        },
      },
    };
  },

  [USER + LOADING](state) {
    return {
      ...state,
      all: {
        ...state.all,
        loader: true,
        error: null,
      },
    };
  },
  [USER + LOADED](state, payload) {
    return {
      ...state,
      all: {
        ...state.all,
        loader: false,
        error: null,
        data: {
          ...state.all.data,
          [payload?.result?.id]: payload?.result,
        },
      },
    };
  },
  [USER + ERROR](state, payload) {
    return {
      ...state,
      all: {
        ...state.all,
        loader: false,
        error: payload.result,
      },
    };
  },

  [USERS + LOADING](state) {
    return {
      ...state,
      all: {
        ...state.all,
        loader: true,
        error: null,
      },
    };
  },
  [USERS + LOADED](state, payload) {
    const data = payload?.result?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      all: {
        ...state.all,
        loader: false,
        error: null,
        data: { ...state.all.data, ...data },
      },
    };
  },
  [USERS + ERROR](state, payload) {
    return {
      ...state,
      all: {
        ...state.all,
        loader: false,
        error: payload.result,
      },
    };
  },

  [USERS_ROLE + LOADING](state) {
    return {
      ...state,
      byRole: {
        ...state.byRole,
        loader: true,
        error: null,
      },
    };
  },
  [USERS_ROLE + LOADED](state, payload) {
    const data = payload?.result?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      byRole: {
        ...state.byRole,
        loader: false,
        error: null,
        data,
      },
    };
  },
  [USERS_ROLE + ERROR](state, payload) {
    return {
      ...state,
      byRole: {
        ...state.byRole,
        loader: false,
        error: payload.result,
      },
    };
  },

  // Reset reducers
  [LOGOUT]() {
    return initialState;
  },
  [LOGOUT + LOADED]() {
    return initialState;
  },
};

export default createReducer(reducers, initialState);
