import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DatePicker from '../../DatePicker';
import FormButton from '../../Button';

import { correspondent } from '../../../redux/reducers/correspondents/selectors';
import { createLoader, updateLoader } from '../../../redux/reducers/courriersDeparture/selectors';
import { selectAuthToken } from '../../../redux/reducers/authentication/selectors';

import clients from '../../../config/clients';
import { WORKFLOW_STATE } from '../../../constants';

const StyleButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: calc(42px - 16px);
`;

const StyledFormGroup = styled(Form.Group)`
  padding-bottom: 16px;

  label {
    color: #3D5278;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const axiosApiInstance = clients.api.client;

function CorrespondentForm({ data, onSubmit, onClose }) {
  const fetchSearchResultsRef = useRef();

  const authToken = useSelector(selectAuthToken);
  const correspondentById = useSelector(correspondent(data?.correspondant_id));
  const isCreating = useSelector(createLoader);
  const isUpdating = useSelector(updateLoader);

  const [formData, setFormData] = useState({ correspondant_id: '', date_correspondance: '', reference: '', workflow_state: WORKFLOW_STATE.PENDING });

  const onChangeHandler = (field) => (val) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };

  const correspondents = (inputValue, callback) => {
    if (fetchSearchResultsRef.current) {
      fetchSearchResultsRef?.current?.cancel();
    }
    fetchSearchResultsRef.current = debounce(async () => {
      const res = await axiosApiInstance.request({
        method: 'get',
        url: '/correspondants',
        params: { limit: 100, page: 1, correspondent_name: inputValue },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      callback(res?.data?.correspondants?.map((val) => ({
        ...val,
        label: val?.nom,
        value: val?.id,
      })));
    }, 500, { maxWait: 700, trailing: true, leading: false });
    fetchSearchResultsRef.current();
  };

  const onSubmitHandler = () => {
    const fieldNotEmpty = Object.values(formData).filter((val) => val);
    if (fieldNotEmpty.length >= 6) {
      onSubmit({ ...formData, correspondant_id: formData?.correspondant_id?.id });
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      setFormData((prevState) => ({
        ...prevState,
        ...data,
        correspondant_id: { ...correspondentById, label: correspondentById?.nom },
      }));
    }
  }, [data]);

  return (
    <form>
      <StyledFormGroup
        controlId="correspondant"
      >
        <Form.Label>
          Correspondant
        </Form.Label>
        <AsyncSelect
          defaultOptions
          cacheOptions
          loadOptions={correspondents}
          placeholder="Choisir Correspondant"
          value={formData?.correspondant_id}
          onChange={onChangeHandler('correspondant_id')}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <DatePicker
          value={formData?.date_correspondance}
          placeholder="Select your date"
          label="Date Correspondance"
          onDateChange={onChangeHandler('date_correspondance')}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <DatePicker
          value={formData?.date_edition}
          placeholder="Select your date"
          label="Date edition"
          onDateChange={onChangeHandler('date_edition')}
        />
      </StyledFormGroup>
      <StyledFormGroup
        controlId="reference"
      >
        <Form.Label>Référence</Form.Label>
        <Form.Control
          name="reference"
          placeholder="Enter you référence"
          value={formData?.reference}
          type="text"
          onChange={({ target: { value } }) => onChangeHandler('reference')(value)}
        />
      </StyledFormGroup>
      <StyledFormGroup
        controlId="objet"
      >
        <Form.Label>Objet</Form.Label>
        <Form.Control
          name="objet"
          placeholder="Enter you objet"
          value={formData?.objet}
          type="text"
          onChange={({ target: { value } }) => onChangeHandler('objet')(value)}
        />
      </StyledFormGroup>
      <StyledFormGroup
        controlId="scan"
      >
        <Form.Label>Scan</Form.Label>
        <Form.Control
          name="scan"
          placeholder="Enter you objet"
          type="file"
          onChange={({ target: { files } }) => onChangeHandler('scan')(files[0])}
        />
      </StyledFormGroup>
      <StyleButtonWrapper>
        <FormButton
          onClick={() => onClose()}
          isLoading={(isCreating || isUpdating)}
          title="Fermer"
        />
        <FormButton
          variant="primary"
          size="lg"
          onClick={onSubmitHandler}
          isLoading={(isCreating || isUpdating)}
          title="Enregistrer"
        />
      </StyleButtonWrapper>
    </form>
  );
}

CorrespondentForm.propTypes = {
  data: PropTypes.shape({
    correspondant_id: PropTypes.shape({}),
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

CorrespondentForm.defaultProps = {
  data: {},
  onSubmit: () => { },
  onClose: () => { },
};

export default CorrespondentForm;
