import { COURRIERS_DEPARTURE, CREATE_COURRIERS_DEPARTURE, DELETE_COURRIERS_DEPARTURE, UPDATE_COURRIERS_DEPARTURE } from './actions';

export const getAll = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/courrier_departs', params },
  type: COURRIERS_DEPARTURE,
});

export const create = (data) => ({
  client: 'api',
  request: { method: 'post', url: '/courrier_departs', data },
  type: CREATE_COURRIERS_DEPARTURE,
});

export const bulkCreate = (data) => ({
  client: 'api',
  request: { method: 'post', url: '/courrier_departs/bulk_create', data },
  type: CREATE_COURRIERS_DEPARTURE,
});

export const updateById = (data) => ({
  client: 'api',
  request: { method: 'put', url: '/courrier_departs/update', data },
  type: UPDATE_COURRIERS_DEPARTURE,
});

export const deleteById = (data) => ({
  client: 'api',
  request: { method: 'delete', url: '/courrier_departs/delete', data },
  type: DELETE_COURRIERS_DEPARTURE,
});
