import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon={toAbsoluteUrl('/media/icons/home.svg')}
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pages</span>
        </div>
      </div>
      <AsideMenuItem
        to='/register-arrive'
        icon={toAbsoluteUrl('/media/icons/folder-open.svg')}
        title={`Registre d'arrivée`}
      />
      <AsideMenuItem
        to='/register-departure'
        icon={toAbsoluteUrl('/media/icons/send2.svg')}
        title={`Registre de départ`}
      />
      <AsideMenuItemWithSub
        title='Registre de classement'
        icon={toAbsoluteUrl('/media/icons/folder-connection.svg')}
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/incoming-mail' title="Courrier d'arrivée" />
        <AsideMenuItem to='/outgoing-mail' title="Courrier de départ" />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/correspondents'
        icon={toAbsoluteUrl('/media/icons/people2.svg')}
        title={`Correspondants`}
      />
    </>
  )
}
