import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DateTime } from 'luxon';
import { activitiesData, activitiesLoader, activitiesPagination } from '../../redux/reducers/dashboard/selectors';
import { getActivities } from '../../redux/reducers/dashboard/actionCreators';

const StyledDataTable = styled(DataTable)`
  border-radius: 10px;
  border: 1px solid rgba(195, 212, 233, 0.5);
  margin-top: 16px;

  .rdt_TableCell, .rdt_TableCol {
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    font-weight: 700;
  }

  .rdt_TableHeadRow {
    background-color: #81CC4D;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    color: #fff;
  }

  .rdt_Pagination {
    border: unset;
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }
`;

const StyledHeading = styled.h3`
  color: #2D2D2D;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-left: 24px;
  position: relative;

  :after {
    background-color: #81CC4D;
    border-radius: 50px;
    content: "";
    display: inline-block;
    height: 8px;
    left: 4px;
    position: absolute;
    top: 14px;
    width: 8px;
  }

  :before {
    border-radius: 50px;
    border: 8px solid #D9F0CA;
    content: "";
    display: inline-block;
    left:0;
    position: absolute;
    top: 10px;
  }
`;

function ActivitiesTable({ startPeriod, endPeriod }) {
  const dispatch = useDispatch();

  const isLoading = useSelector(activitiesLoader);
  const pagination = useSelector(activitiesPagination);
  const tableData = Object.values((useSelector(activitiesData)));

  const columns = [
    {
      name: 'Auteur',
      selector: (row) => row.author,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Date',
      selector: (row) => DateTime.fromJSDate(new Date(row.created_at)).toFormat('yyyy-MM-dd'),
      sortable: true,
      width: '160px',
    },
    {
      name: 'Type Entité',
      selector: (row) => row.trackable_type,
      sortable: true,
      width: 'unset',
      wrap: true,
    },
    {
      name: 'Opération',
      selector: (row) => row.key,
      sortable: true,
      width: 'unset',
      wrap: true,
    },
    {
      name: 'Description',
      selector: (row) => row.display_text,
      sortable: true,
      width: 'unset',
      wrap: true,
    },
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(getActivities({ limit: newPerPage, page, start_period: startPeriod, end_period: endPeriod }));
  };

  const handlePageChange = (page) => {
    dispatch(getActivities({ limit: pagination?.per_page, page, start_period: startPeriod, end_period: endPeriod }));
  };

  useEffect(() => {
    dispatch(getActivities({ start_period: startPeriod, end_period: endPeriod }));
  }, []);

  useEffect(() => {
    dispatch(getActivities({ start_period: startPeriod, end_period: endPeriod }));
  }, [startPeriod, endPeriod]);

  return (
    <>
      <StyledHeading>Activités</StyledHeading>
      <StyledDataTable
        columns={isLoading ? [] : columns}
        data={isLoading ? [] : tableData}
        progressPending={isLoading}
        pagination
        paginationServer
        paginationTotalRows={pagination?.total_objects || 0}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        dense
        noDataComponent={<p style={{ margin: '0', padding: 24 }}>Il n&apos;y a aucun enregistrement à afficher</p>}
      />

    </>
  );
}

ActivitiesTable.propTypes = {
  startPeriod: PropTypes.string,
  endPeriod: PropTypes.string,
};

ActivitiesTable.defaultProps = {
  startPeriod: null,
  endPeriod: null,
};

export default ActivitiesTable;
