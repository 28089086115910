import { Route, Routes, Navigate } from 'react-router-dom';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import Correspondents from '../pages/correspondents';
import Dashboard from '../pages/dashboard';
import OutgoingMail from '../pages/outgoing-mail';
import IncomingMail from '../pages/incoming-mail';
import RegisterArrive from '../pages/register-arrive';
import RegisterDeparture from '../pages/register-departure';
import { getUserById } from '../../redux/reducers/user/actionCreators';
import { selectCurrentUserId } from '../../redux/reducers/authentication/selectors';

function PrivateRoutes() {
  const dispatch = useDispatch();

  const userId = useSelector(selectCurrentUserId);

  useEffect(() => {
    dispatch(getUserById(userId));
  }, []);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="register-arrive" element={<RegisterArrive />} />
        <Route path="register-departure" element={<RegisterDeparture />} />
        <Route path="correspondents" element={<Correspondents />} />
        <Route path="incoming-mail" element={<IncomingMail />} />
        <Route path="outgoing-mail" element={<OutgoingMail />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
}

export { PrivateRoutes };
