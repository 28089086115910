import get from 'lodash/get';
import { REDUCERS_NAME } from '../../constants';

const reducerName = REDUCERS_NAME.DASHBOARD;

export const statsData = (state) => get(state, `${reducerName}.stats.data`, {});
export const statsError = (state) => get(state, `${reducerName}.stats.error`);
export const statsLoader = (state) => get(state, `${reducerName}.stats.loader`);

export const activitiesData = (state) => get(state, `${reducerName}.activities.data`, {});
export const activitiesError = (state) => get(state, `${reducerName}.activities.error`);
export const activitiesLoader = (state) => get(state, `${reducerName}.activities.loader`);
export const activitiesPagination = (state) => get(state, `${reducerName}.activities.pagination`);

export const couriersWithoutAssignmentData = (state) => get(state, `${reducerName}.couriersWithoutAssignment.data`, {});
export const couriersWithoutAssignmentError = (state) => get(state, `${reducerName}.couriersWithoutAssignment.error`);
export const couriersWithoutAssignmentLoader = (state) => get(state, `${reducerName}.couriersWithoutAssignment.loader`);
export const couriersWithoutAssignmentPagination = (state) => get(state, `${reducerName}.couriersWithoutAssignment.pagination`);

export const couriersWithoutReassignmentData = (state) => get(state, `${reducerName}.couriersWithoutReassignment.data`, {});
export const couriersWithoutReassignmentError = (state) => get(state, `${reducerName}.couriersWithoutReassignment.error`);
export const couriersWithoutReassignmentLoader = (state) => get(state, `${reducerName}.couriersWithoutReassignment.loader`);
export const couriersWithoutReassignmentPagination = (state) => get(state, `${reducerName}.couriersWithoutReassignment.pagination`);
