import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WORKFLOW_STATE_KEY } from '../../constants';

const StyledModalTitle = styled(Modal.Title)`
  color: #1A202C;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`;

function PreviewMailModal({
  data,
  isVisible,
  onClose,
  previewKeysArray,
  title,
}) {
  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        {title && (<StyledModalTitle>{title}</StyledModalTitle>)}
      </Modal.Header>
      <Modal.Body>
        {previewKeysArray.map(({ label, key }) => {
          let val = data[key];
          if (key === 'users' && val?.length > 0) {
            val = val?.map((val) => (
              <p key={val?.email}>
                {`(${val?.first_name} ${val?.last_name})`}
                {' '}
                {val?.email}
              </p>
            ));
          } else if (key === 'users' && val?.length < 1) {
            val = <p>-</p>;
          } else if (key === 'actions' && val?.length > 0) {
            val = val?.map((val) => <p key={val?.nom}>{val?.nom}</p>);
          } else if (key === 'actions' && val?.length < 1) {
            val = <p>-</p>;
          } else if (key === 'scan') {
            val = <p>{data.scan ? <a href={`https://${data.scan}`} target="_blank" rel="noreferrer">Afficher le courrier</a> : '-'}</p>;
          } else if (key === 'workflow_state') {
            val = <p>{WORKFLOW_STATE_KEY[data?.workflow_state?.toLowerCase()] || '-'}</p>;
          } else {
            val = <p>{val || '-'}</p>;
          }
          return (
            <div key={key}>
              <h4>{label}</h4>
              {val}
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
}

PreviewMailModal.propTypes = {
  previewKeysArray: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({
    scan: PropTypes.string,
    workflow_state: PropTypes.string,
  }),
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

PreviewMailModal.defaultProps = {
  previewKeysArray: [],
  data: {},
  isVisible: false,
  onClose: () => { },
  title: null,
};

export default PreviewMailModal;
