import { createReducer } from '../../utility';
import { BASIC_STATE, ERROR, LOADED, LOADING } from '../../middleware/actions';
import { LOGOUT } from '../authentication/actions';
import { ACTIVITIES, COURIERS_WITHOUT_ASSIGNMENT, COURIERS_WITHOUT_REASSIGNMENT, STATS } from './actions';

const initialState = {
  stats: BASIC_STATE,
  activities: {
    ...BASIC_STATE,
    pagination: {
      page: 1,
      per_page: 10,
      total_objects: 0,
      total_pages: 0,
      links: {
        first: '',
        last: '',
        next: '',
      },
    },
  },
  couriersWithoutAssignment: {
    ...BASIC_STATE,
    pagination: {
      page: 1,
      per_page: 10,
      total_objects: 0,
      total_pages: 0,
      links: {
        first: '',
        last: '',
        next: '',
      },
    },
  },
  couriersWithoutReassignment: {
    ...BASIC_STATE,
    pagination: {
      page: 1,
      per_page: 10,
      total_objects: 0,
      total_pages: 0,
      links: {
        first: '',
        last: '',
        next: '',
      },
    },
  },
};

const reducers = {
  [STATS + LOADING](state) {
    return {
      ...state,
      stats: {
        ...state.stats,
        loader: true,
      },
    };
  },
  [STATS + LOADED](state, payload) {
    return {
      ...state,
      stats: {
        ...state.stats,
        loader: false,
        error: null,
        data: payload.result,
      },
    };
  },
  [STATS + ERROR](state, payload) {
    return {
      ...state,
      stats: {
        ...state.stats,
        loader: false,
        error: payload.result,
      },
    };
  },

  [ACTIVITIES + LOADING](state) {
    return {
      ...state,
      activities: {
        ...state.activities,
        loader: true,
      },
    };
  },
  [ACTIVITIES + LOADED](state, payload) {
    const activities = payload?.result['public_activity/activities'];
    const data = activities?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      activities: {
        ...state.activities,
        loader: false,
        error: null,
        data: { ...state.activities.data, ...data },
        pagination: {
          ...state.activities.pagination,
          ...payload?.result?.meta.pagination,
          page: payload?.originalPayload?.page,
        },
      },
    };
  },
  [ACTIVITIES + ERROR](state, payload) {
    return {
      ...state,
      activities: {
        ...state.activities,
        loader: false,
        error: payload.result,
      },
    };
  },

  [COURIERS_WITHOUT_ASSIGNMENT + LOADING](state) {
    return {
      ...state,
      couriersWithoutAssignment: {
        ...state.couriersWithoutAssignment,
        loader: true,
      },
    };
  },
  [COURIERS_WITHOUT_ASSIGNMENT + LOADED](state, payload) {
    const courrierArrivees = payload?.result?.courrier_arrivees;
    const data = courrierArrivees?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      couriersWithoutAssignment: {
        ...state.couriersWithoutAssignment,
        loader: false,
        error: null,
        data: { ...state.couriersWithoutAssignment.data, ...data },
        pagination: {
          ...state.couriersWithoutAssignment.pagination,
          ...payload?.result?.meta.pagination,
          page: payload?.originalPayload?.page,
        },
      },
    };
  },
  [COURIERS_WITHOUT_ASSIGNMENT + ERROR](state, payload) {
    return {
      ...state,
      couriersWithoutAssignment: {
        ...state.couriersWithoutAssignment,
        loader: false,
        error: payload.result,
      },
    };
  },

  [COURIERS_WITHOUT_REASSIGNMENT + LOADING](state) {
    return {
      ...state,
      couriersWithoutReassignment: {
        ...state.couriersWithoutReassignment,
        loader: true,
      },
    };
  },
  [COURIERS_WITHOUT_REASSIGNMENT + LOADED](state, payload) {
    const courrierArrivees = payload?.result?.courrier_arrivees;
    const data = courrierArrivees?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      couriersWithoutReassignment: {
        ...state.couriersWithoutReassignment,
        loader: false,
        error: null,
        data: { ...state.couriersWithoutReassignment.data, ...data },
        pagination: {
          ...state.couriersWithoutReassignment.pagination,
          ...payload?.result?.meta.pagination,
          page: payload?.originalPayload?.page,
        },
      },
    };
  },
  [COURIERS_WITHOUT_REASSIGNMENT + ERROR](state, payload) {
    return {
      ...state,
      couriersWithoutReassignment: {
        ...state.couriersWithoutReassignment,
        loader: false,
        error: payload.result,
      },
    };
  },

  // Reset reducers
  [LOGOUT]() {
    return initialState;
  },
  [LOGOUT + LOADED]() {
    return initialState;
  },
};

export default createReducer(reducers, initialState);
