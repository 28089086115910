import get from 'lodash/get';
import { REDUCERS_NAME } from '../../constants';

const reducerName = REDUCERS_NAME.MAIL_CLASSIFICATION;

export const departureError = (state) => get(state, `${reducerName}.departs.error`);
export const departureGetAll = (state) => get(state, `${reducerName}.departs.data`, {});
export const departureLoader = (state) => get(state, `${reducerName}.departs.loader`);
export const departurePagination = (state) => get(state, `${reducerName}.departs.pagination`);

export const arrivalError = (state) => get(state, `${reducerName}.arrival.error`);
export const arrivalGetAll = (state) => get(state, `${reducerName}.arrival.data`, {});
export const arrivalLoader = (state) => get(state, `${reducerName}.arrival.loader`);
export const arrivalPagination = (state) => get(state, `${reducerName}.arrival.pagination`);
