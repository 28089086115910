/* eslint-disable react/jsx-indent */
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { App } from '../App';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage } from '../modules/auth';
import { PrivateRoutes } from './PrivateRoutes';
import { selectIsLoggedIn } from '../../redux/reducers/authentication/selectors';

const { PUBLIC_URL } = process.env;

function AppRoutes() {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {isLoggedIn ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export { AppRoutes };
