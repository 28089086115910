import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ActivitiesTable from '../../../components/ActivitiesTable';
import CouriersWithoutAssignmentTable from '../../../components/CouriersWithoutAssignmentTable';
import CouriersWithoutReassignmentTable from '../../../components/CouriersWithoutReassignmentTable';
import DatePicker from '../../../components/DatePicker';
import FormButton from '../../../components/Button';

import { statsData } from '../../../redux/reducers/dashboard/selectors';
import { getStats } from '../../../redux/reducers/dashboard/actionCreators';

import { toAbsoluteUrl } from '../../../_metronic/helpers';

const StyledCard = styled.div`
  .table-header, .item {
    display: flex;
  }

  .table-header, .item {
    p {
      flex: 1;
      width: 25%;
    }
  }

  .rdt_Pagination {
    border: unset;
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }
`;

const StyledIconWrapper = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 24px;
  position: relative;
  width: 48px;

  &:after {
    background-color: ${({ bgColor }) => (bgColor || '#3563E9')};
    border-radius: 50%;
    content: "";
    display: inline-block;
    left: 0px;
    position: absolute;
    top: 0px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
  &:before {
    border-radius: 50%;
    background-color: ${({ bdColor }) => (bdColor || 'rgba(53, 99, 233, 0.3)')};
    height: 58px;
    width: 58px;
    content: "";
    display: inline-block;
    left:-5px;
    position: absolute;
    top: -5px;
  }

  img {
    z-index: 3;
  }
`;

const CardBody = styled(Card.Body)`
  align-items: center;
  color: #3D5278;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
`;

const StyleCard = styled(Card)`
  border: 1px solid rgba(195, 212, 233, 1);
  border-radius: 10px;
  display: block;
  margin-bottom: 24px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding-top: 16px;

  label {
    color: #3D5278;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  button {
    top: 18px;
  }

  .icofont-calendar {
    font-size: 18px;
  }
`;

const StyledFilterBar = styled.div`
  margin-bottom: 30px;

  > div{
    align-items: flex-end;
  }

  input.form-control {
    background: #F6F7F9;
    border-radius: 10px;
    border: unset;
    color: #90A3BF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 56px;
    line-height: 20px;
    padding: 16px 32px;
  }
`;

function Dashboard() {
  const dispatch = useDispatch();

  const stats = useSelector(statsData);

  const start = DateTime.now().minus({ month: 1 }).toISODate();
  const end = DateTime.now().toISODate();

  const [fieldsData, setFieldsData] = useState({ start_period: start, end_period: end });
  const [dates, setDates] = useState({ start_period: start, end_period: end });

  const onChangeHandler = (field) => (val) => {
    setFieldsData((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };

  const onClickHandler = () => {
    setDates(fieldsData);
  };

  useEffect(() => {
    dispatch(getStats({ start_period: dates.start_period, end_period: dates.end_period }));
  }, []);

  useEffect(() => {
    dispatch(getStats({ start_period: dates.start_period, end_period: dates.end_period }));
  }, [dates]);

  return (
    <div className="card">
      <div className="card-header border-1">
        <h3 className="card-title">
          Statistiques
        </h3>
      </div>
      <StyledCard className="card-body py-3">
        <StyledFilterBar>
          <Row>
            <Col>
              <StyledDatePicker
                value={fieldsData?.start_period}
                placeholder="Select start date"
                label="Date de début"
                onDateChange={onChangeHandler('start_period')}
              />
            </Col>
            <Col>
              <StyledDatePicker
                value={fieldsData?.end_period}
                placeholder="Select end date"
                label="Date de fin"
                onDateChange={onChangeHandler('end_period')}
              />
            </Col>
            <Col>
              <FormButton
                title="Rechercher"
                variant="primary"
                onClick={onClickHandler}
              />
            </Col>
          </Row>
        </StyledFilterBar>
        <Row>
          <Col>
            <StyleCard as={Link} to="/register-arrive">
              <CardBody>
                <StyledIconWrapper bgColor="#7AC943" bdColor="#7AC94333">
                  <img src={toAbsoluteUrl('/media/icons/directbox-send.svg')} alt="" />
                </StyledIconWrapper>
                {stats?.received_couriers}
                {' '}
                de courriers reçus
              </CardBody>
            </StyleCard>
          </Col>
          <Col>
            <StyleCard as={Link} to="/register-departure">
              <CardBody>
                <StyledIconWrapper bgColor="#FF0000" bdColor="#FF000033">
                  <img src={toAbsoluteUrl('/media/icons/send.svg')} alt="" />
                </StyledIconWrapper>
                {stats?.sent_couriers}
                {' '}
                de courriers expédiés
              </CardBody>
            </StyleCard>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyleCard as={Link} to="/incoming-mail">
              <CardBody>
                <StyledIconWrapper bgColor="#2D62EB" bdColor="#2D62EB4D">
                  <img src={toAbsoluteUrl('/media/icons/trend-up.svg')} alt="" />
                </StyledIconWrapper>
                {stats?.rate_of_assignment}
                {' '}
                % d&apos;imputations
              </CardBody>
            </StyleCard>
          </Col>
          <Col>
            <StyleCard as={Link} to="/correspondents">
              <CardBody>
                <StyledIconWrapper bgColor="#FF9314" bdColor="#FF931433">
                  <img src={toAbsoluteUrl('/media/icons/receipt.svg')} alt="" />
                </StyledIconWrapper>
                {stats?.rate_of_reassignment}
                {' '}
                % de ré-imputation
              </CardBody>
            </StyleCard>
          </Col>
        </Row>
        <ActivitiesTable startPeriod={dates.start_period} endPeriod={dates.end_period} />
        <CouriersWithoutAssignmentTable startPeriod={dates.start_period} endPeriod={dates.end_period} />
        <CouriersWithoutReassignmentTable startPeriod={dates.start_period} endPeriod={dates.end_period} />
      </StyledCard>
    </div>
  );
}

export default Dashboard;
