import { CORRESPONDENTS, CORRESPONDENT_BY_ID, CREATE_CORRESPONDENT, DELETE_CORRESPONDENT, UPDATE_CORRESPONDENT } from './actions';

export const getCorrespondents = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/correspondants', params },
  type: CORRESPONDENTS,
});

export const createCorrespondent = (data) => ({
  client: 'api',
  request: { method: 'post', url: '/correspondants', data: { ...data } },
  type: CREATE_CORRESPONDENT,
});

export const updateCorrespondent = (data) => ({
  client: 'api',
  request: { method: 'put', url: '/correspondants/update', data: { ...data } },
  type: UPDATE_CORRESPONDENT,
});

export const deleteCorrespondent = (data, callback) => ({
  client: 'api',
  request: { method: 'delete', url: '/correspondants/delete', data: { ...data } },
  type: DELETE_CORRESPONDENT,
  callback,
});

export const getCorrespondentById = (id, callback) => ({
  client: 'api',
  request: { method: 'get', url: `/correspondants/${id}` },
  type: CORRESPONDENT_BY_ID,
  callback,
});
