import { Modal } from 'react-bootstrap';
import { string, node, bool, func } from 'prop-types';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
        max-width: 730px;
    }
  }

  .modal-content {
    border-radius: 19px;
    padding: 24px;

    label {
      color: #2D2D2D;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      padding-left: 24px;
      position: relative;

      :after {
        background-color: #3563E9;
        border-radius: 50px;
        content: "";
        display: inline-block;
        height: 8px;
        left: 4px;
        position: absolute;
        top: 14px;
        width: 8px;
      }

      :before {
        border-radius: 50px;
        border: 8px solid rgba(53, 99, 233, 0.3);;
        content: "";
        display: inline-block;
        left:0;
        position: absolute;
        top: 10px;
      }
    }

    input.form-control, select {
      background: #F6F7F9;
      border-radius: 10px;
      border: unset;
      color: #90A3BF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 56px;
      line-height: 20px;
      padding: 16px 32px;
    }
  }

  .modal-title {
    color: #151515;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .modal-header{
    border-bottom: unset;
    padding: 0;
    padding-bottom: 24px;
  }

  .modal-body{
    padding: 0;
  }
`;

function Popup({ isShow, onHide, title, children }: any) {
  return (
    <StyledModal show={isShow} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </StyledModal>
  );
}

Popup.propTypes = {
  isShow: bool,
  onHide: func,
  title: string,
  children: node,
};

Popup.defaultProps = {
  isShow: false,
  onHide: () => { },
  title: '',
  children: null,
};

export default Popup;
