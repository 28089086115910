import { REDUCERS_NAME } from '../../constants';
import { createActionName } from '../../utility';

export const reducerName = REDUCERS_NAME.CORRESPONDENTS;

export const CORRESPONDENT_BY_ID = createActionName(reducerName, 'CORRESPONDENT_BY_ID');
export const CORRESPONDENTS = createActionName(reducerName, 'CORRESPONDENTS');
export const CREATE_CORRESPONDENT = createActionName(reducerName, 'CREATE_CORRESPONDENT');
export const DELETE_CORRESPONDENT = createActionName(reducerName, 'DELETE_CORRESPONDENT');
export const UPDATE_CORRESPONDENT = createActionName(reducerName, 'UPDATE_CORRESPONDENT');
