import { MAIL_CLASSIFICATION_ARRIVAL, MAIL_CLASSIFICATION_DEPARTURE } from './actions';

export const getAllArrival = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/classement_courrier/arrival', params },
  type: MAIL_CLASSIFICATION_ARRIVAL,
});

export const getAllDeparture = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/classement_courrier/departure', params },
  type: MAIL_CLASSIFICATION_DEPARTURE,
});
