import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActionButton from '../ActionButton';
import Popup from '../Popup';
import PreviewMailFileModal from '../PreviewMailFileModal';
import PreviewMailModal from '../PreviewMailModal';
import RegisterDepartureForm from '../Forms/RegisterDeparture';
import WarningModal from '../WarningModal';

import { getCorrespondentById } from '../../redux/reducers/correspondents/actionCreators';
import { selectCurrentUserId } from '../../redux/reducers/authentication/selectors';
import * as courriersDepartureAction from '../../redux/reducers/courriersDeparture/actionCreators';
import * as courriersDepartureSelectors from '../../redux/reducers/courriersDeparture/selectors';

import { WORKFLOW_STATE } from '../../constants';

const ActionsRow = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px;

  button {
    margin-right: 8px;
  }
`;

function RegisterDeparture({ data }) {
  const dispatch = useDispatch();

  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [isConfirmSent, setConfirmSent] = useState(false);
  const [isEditPopupShow, setEditPopupShow] = useState(false);
  const [isShowFile, setShowFile] = useState(false);
  const [isShowPreview, setShowPreview] = useState(false);

  const isDeleteLoader = useSelector(courriersDepartureSelectors.deleteLoader);
  const isUpdateLoader = useSelector(courriersDepartureSelectors.updateLoader);
  const pagination = useSelector(courriersDepartureSelectors.pagination);
  const userId = useSelector(selectCurrentUserId);

  const showFilePreviewHandler = () => {
    setShowFile(true);
  };

  const previewMailHandler = () => {
    setShowPreview(true);
  };

  const deleteRecordHandler = async () => {
    await dispatch(courriersDepartureAction.deleteById({ id: data?.id }));
    await dispatch(courriersDepartureAction.getAll({ limit: pagination.per_page, page: 1 }));
  };

  const editRecordHandler = async () => {
    await dispatch(getCorrespondentById(data.correspondant_id));
    setEditPopupShow(true);
  };

  const sentMailHandler = async () => {
    await dispatch(courriersDepartureAction.updateById({
      id: data?.id,
      user_id: userId,
      workflow_state: WORKFLOW_STATE.SENT,
    }));
    setConfirmSent(false);
  };

  const onSubmitHandler = async (data) => {
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    await dispatch(courriersDepartureAction.updateById(body));
    await dispatch(courriersDepartureAction.getAll({ limit: pagination.per_page, page: 1 }));
    setEditPopupShow(false);
  };

  const deleteRecord = (
    <ActionButton
      onClick={() => setConfirmDelete(true)}
      icon="trash"
      title="Supprimer"
      variant="danger"
    />
  );

  const sendMail = (
    <ActionButton
      onClick={() => setConfirmSent(true)}
      icon="paper-plane"
      title="Expédier"
    />
  );

  const editRecord = (
    <ActionButton
      onClick={editRecordHandler}
      icon="pen"
      title="Modifier"
    />
  );

  const showFilePreview = (
    <ActionButton
      onClick={() => showFilePreviewHandler(data)}
      icon="file"
      title="Aperçu fichier"
    />
  );

  const previewMail = (
    <ActionButton
      onClick={() => previewMailHandler(data)}
      icon="eye"
      title="Détails"
    />
  );

  const preview = [
    { label: 'Créé le', key: 'created_at' },
    { label: 'Date correspondance', key: 'date_correspondance' },
    { label: 'Date édition', key: 'date_edition' },
    { label: 'mis à jour le', key: 'updated_at' },
    { label: 'Priorité', key: 'priorite' },
    { label: 'Référence', key: 'reference' },
    { label: 'Fichier', key: 'scan' },
    { label: 'Séquence', key: 'sequence' },
    { label: 'Statut', key: 'workflow_state' },
    { label: 'Commentaire', key: 'commentaire' },
  ];

  return (
    <ActionsRow>
      {editRecord}
      {previewMail}
      {data.scan && showFilePreview}
      {WORKFLOW_STATE.SENT !== data.workflow_state && sendMail}
      {deleteRecord}

      <Popup isShow={isEditPopupShow} onHide={() => setEditPopupShow(false)} title="Courrier Départ">
        <RegisterDepartureForm
          data={pick(data, ['id', 'correspondant_id', 'date_correspondance', 'date_edition', 'reference', 'objet'])}
          onClose={() => setEditPopupShow(false)}
          onSubmit={onSubmitHandler}
        />
      </Popup>

      <PreviewMailModal
        data={data}
        isVisible={isShowPreview}
        onClose={() => setShowPreview(false)}
        previewKeysArray={preview}
        title="Aperçu courrier"
      />

      <PreviewMailFileModal
        data={data}
        isVisible={isShowFile}
        onClose={() => setShowFile(false)}
        title="Prévisualisation Courrier"
      />

      <WarningModal
        description="Êtes-vous sûr de vouloir supprimer ce courrier?"
        isLoading={isDeleteLoader}
        isVisible={isConfirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={deleteRecordHandler}
        title="Supprimer Courrier"
      />

      <WarningModal
        description="Êtes-vous sûr de vouloir envoyer cet e-mail?"
        isLoading={isUpdateLoader}
        isVisible={isConfirmSent}
        onClose={() => setConfirmSent(false)}
        onSubmit={sentMailHandler}
        title="Courrier envoyé"
        submitButtonText="Sent"
      />
    </ActionsRow>
  );
}

RegisterDeparture.propTypes = {
  data: PropTypes.shape({
    correspondant_id: PropTypes.number,
    id: PropTypes.string,
    scan: PropTypes.string,
    user_id: PropTypes.number,
    workflow_state: PropTypes.string,
  }),
};

RegisterDeparture.defaultProps = {
  data: {},
};

export default RegisterDeparture;
