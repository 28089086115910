import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { createLoader, updateLoader } from '../../redux/reducers/correspondents/selectors';

const StyleButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 42px;

  button {
    background: #F6F7F9;
    border-radius: 19px;
    border: 1px solid rgba(195, 212, 233, 0.5);
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-left: 16px;
    width: 209px;

    type[submit] {
      background: #7AC943;
    }
  }
`;

interface Props {
  data: {
    nom: string,
    sigle: string
  };
  onSubmit: Function;
  onClose: Function;
}

function CorrespondentForm({ data, onSubmit, onClose }: Props) {
  const isCreating = useSelector(createLoader);
  const isUpdating = useSelector(updateLoader);
  return (
    <Formik
      initialValues={data}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={Yup.object({
        nom: Yup.string().required('Required'),
        sigle: Yup.string().required('Required'),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Form.Group
            className="mb-4"
            controlId="nom"
          >
            <Form.Label>
              Nom
            </Form.Label>
            <Form.Control
              name="nom"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your nom"
              type="text"
              value={values.nom}
              isInvalid={errors.nom === 'Required' && touched.nom}
            />
            <Form.Control.Feedback type="invalid">
              {errors.nom}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="sigle"
          >
            <Form.Label>Sigle</Form.Label>
            <Form.Control
              name="sigle"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your sigle"
              type="text"
              value={values.sigle}
              isInvalid={errors.sigle === 'Required' && touched.sigle}
            />
            <Form.Control.Feedback type="invalid">
              {errors.sigle}
            </Form.Control.Feedback>
          </Form.Group>
          <StyleButtonWrapper>
            <Button variant="light" type="button" onClick={() => onClose()}>Fermer</Button>
            <Button variant="light" type="submit" disabled={isCreating || isUpdating}>
              {(isCreating || isUpdating) ? (
                <Spinner animation="border" />
              ) : ('Enregistrer')}
            </Button>
          </StyleButtonWrapper>
        </form>
      )}
    </Formik>
  );
}

CorrespondentForm.propTypes = {
  data: PropTypes.shape({
    nom: PropTypes.string,
    sigle: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

CorrespondentForm.defaultProps = {
  data: {},
  onSubmit: () => { },
};

export default CorrespondentForm;
