import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import Popup from '../../../components/Popup';
import CorrespondentForm from '../../../components/CorrespondentForm';

import * as correspondentsAction from '../../../redux/reducers/correspondents/actionCreators';
import * as correspondentsSelectors from '../../../redux/reducers/correspondents/selectors';

import { KTSVG } from '../../../_metronic/helpers';
import withAuth from '../../../hoc/withAuth';

const StyledCard = styled.div`
  .table-header, .item {
    display: flex;
  }

  .table-header, .item {
    p {
      flex: 1;
      width: 25%;
    }
  }

  .row-actions {
    button {
      &.delete, &.edit {
        border: 0;
        background: transparent;
        margin: 0;
        padding: 0;
      }
      &.delete {
        margin-right: 8px;
      }
    }
  }

  .rdt_Pagination {
    border: unset;
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }
`;

const TableHeader = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    color: #3D5278;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
  }

  .actions {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      align-items: center;
      background: #7AC943;
      border-radius: 34px;
      color: #FFFFFF;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 24px;
      margin-left: 16px;
      width: 209px;

      svg {
        color: #FFFFFF;
      }
    }

    input {
      background: #FFFFFF;
      border-radius: 70px;
      border: 1px solid rgba(195, 212, 233, 0.4);
      color: #90A3BF;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      width: 254px;
    }
  }
`;

const StyledDataTable = styled(DataTable)`
  border-radius: 10px;
  border: 1px solid rgba(195, 212, 233, 0.5);
  margin-top: 16px;

  .rdt_TableCell, .rdt_TableCol {
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    font-weight: 700;
  }

  .rdt_TableHeadRow {
    background-color: #81CC4D;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    color: #fff;
  }
`;

function Correspondents() {
  const dispatch = useDispatch();
  const fetchSearchResultsRef = useRef();

  const allCorrespondents = Object.values((useSelector(correspondentsSelectors.correspondents)));
  const isLoading = useSelector(correspondentsSelectors.loader);
  const pagination = useSelector(correspondentsSelectors.pagination);

  const [isShow, setShow] = useState(false);
  const [correspondent, setCorrespondent] = useState({ nom: '', sigle: '', id: NaN });
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    dispatch(correspondentsAction.getCorrespondents({ limit: pagination.per_page, page: 1 }));
  }, []);

  const data = useMemo(() => allCorrespondents.map(({ id, nom, sigle }) => ({
    id, nom, sigle: sigle || '-',
  })), [allCorrespondents]);

  const onEditHandler = async (data) => {
    setShow(true);
    setCorrespondent(data);
  };

  const onDeleteHandler = (row) => {
    dispatch(correspondentsAction.deleteCorrespondent({ id: row?.id }, () => {
      dispatch(correspondentsAction.getCorrespondents({ limit: pagination.per_page, page: pagination.page, correspondent_name: filterText }));
    }));
  };

  const columns = [
    {
      name: 'Sr.',
      selector: (row) => row.id,
      sortable: true,
      width: '80px',
    },
    {
      name: 'Nom',
      selector: (row) => row.nom,
      sortable: true,
      width: 'unset',
    },
    {
      name: 'Sigle',
      selector: (row) => row.sigle,
      sortable: true,
      width: '160px',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <div className="row-actions">
          <button
            onClick={() => onDeleteHandler(row)}
            className="delete"
          >
            <KTSVG path="/media/icons/trash.svg" />
          </button>
          <button
            onClick={() => onEditHandler(row)}
            className="edit"
          >
            <KTSVG path="/media/icons/edit.svg" />
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
      name: 'Actions',
    },
  ];

  const handleClose = () => {
    setShow(false);
    setCorrespondent({ nom: '', sigle: '', id: NaN });
  };

  const onSubmitHandler = async (data) => {
    if (correspondent.id) {
      await dispatch(correspondentsAction.updateCorrespondent({
        ...data,
        id: correspondent.id,
      }));
    } else {
      await dispatch(correspondentsAction.createCorrespondent(data));
    }
    handleClose();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(correspondentsAction.getCorrespondents({ limit: newPerPage, page, correspondent_name: filterText }));
  };

  const handlePageChange = (page) => {
    dispatch(correspondentsAction.getCorrespondents({ limit: pagination.per_page, page, correspondent_name: filterText }));
  };

  const onInputChangeHandler = (val) => {
    setFilterText(val);
    if (fetchSearchResultsRef.current) {
      fetchSearchResultsRef?.current?.cancel();
    }
    fetchSearchResultsRef.current = debounce(async () => {
      await dispatch(correspondentsAction.getCorrespondents({ limit: pagination.per_page, page: 1, correspondent_name: val }));
    }, 500, { maxWait: 700, trailing: true, leading: false });
    fetchSearchResultsRef.current();
  };

  return (
    <div className="card mb-5 mb-xl-8">
      <div className="card-header border-1">
        <h3 className="card-title">
          Liste Correspondants
        </h3>
      </div>
      <StyledCard className="card-body py-3">
        <TableHeader>
          <h4>Correspondants</h4>
          <div className="actions">
            <Form.Control
              type="search"
              placeholder="Recherche par nom"
              value={filterText}
              onChange={({ target: { value } }) => onInputChangeHandler(value)}
            />
            <Button
              variant="light"
              onClick={() => setShow(true)}
            >
              <KTSVG path="/media/icons/duotune/arrows/arr075.svg" />
              Nouveau
            </Button>
          </div>
        </TableHeader>
        <StyledDataTable
          columns={isLoading ? [] : columns}
          data={isLoading ? [] : data}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          pagination
          paginationServer
          paginationTotalRows={pagination.total_objects}
          progressPending={isLoading}
          noDataComponent={<p style={{ margin: '0', padding: 24 }}>Il n&apos;y a aucun enregistrement à afficher</p>}
        />
      </StyledCard>
      <Popup isShow={isShow} onHide={handleClose} title="Formulaire Correspondant">
        <CorrespondentForm
          data={correspondent}
          onSubmit={onSubmitHandler}
          onClose={handleClose}
        />
      </Popup>
    </div>
  );
}

export default withAuth(Correspondents);
