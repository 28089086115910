import { useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { cloneDeep, debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

import DatePicker from '../DatePicker';

import { bulkCreate, getAll } from '../../redux/reducers/courriersArrivee/actionCreators';
import { pagination as paginationSelector } from '../../redux/reducers/courriersArrivee/selectors';
import { selectAuthToken, selectCurrentUserId } from '../../redux/reducers/authentication/selectors';

import clients from '../../config/clients';
import { WORKFLOW_STATE } from '../../constants';

const StyleButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 32px;

  && {
    button {
      background: #F6F7F9;
      border-radius: 19px;
      border: 1px solid rgba(195, 212, 233, 0.5) !important;
      color: #90A3BF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      height: 56px;
      line-height: 24px;
      margin-left: 16px;
      padding: 0px 20px;
      width: 209px;
    }
  }
`;

const SubmitButton = styled(Button)`
  &&& {
    background: #7AC943;
    color: #FFFFFF;
    border: unset !important;

    &:hover {
      background: #7AC943 !important;
    }
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    background: #FF4423;
    color: #FFFFFF;
    border: unset !important;

    &:hover {
      background: #FF4423 !important;
      color: #FFFFFF !important;
    }
  }
`;

const StyledFormGroup = styled(Form.Group)`
  padding-top: 16px;

  label {
    color: #3D5278;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding-top: 16px;

  label {
    color: #3D5278;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .icofont-calendar {
    font-size: 18px;
  }
`;

const INITIAL_FIELDS = { correspondant_id: '', date_correspondance: '', reference: '', workflow_state: WORKFLOW_STATE.TRANSMITTED_SECRETARY, user_id: '' };

const axiosApiInstance = clients.api.client;

function RegisterArriveBulkForm() {
  const fetchSearchResultsRef = useRef();
  const dispatch = useDispatch();

  const authToken = useSelector(selectAuthToken);
  const userId = useSelector(selectCurrentUserId);
  const pagination = useSelector(paginationSelector);

  const [fieldsData, setFieldsData] = useState([cloneDeep(INITIAL_FIELDS)]);
  const [isLoading, setLoading] = useState(false);

  const onChangeHandler = (field, index) => (val) => {
    const data = [...fieldsData];
    const row = data[index];
    row[field] = val;
    data[index] = row;
    setFieldsData(data);
  };

  const onAddMoreRowHandler = () => {
    const data = [...fieldsData];
    data.push(cloneDeep(INITIAL_FIELDS));
    setFieldsData(data);
  };

  const onRemoveLastRowHandler = () => {
    const { length } = fieldsData;
    if (length > 1) {
      setFieldsData(fieldsData.filter((element, index) => index < length - 1));
    }
  };

  const correspondents = (inputValue, callback) => {
    if (fetchSearchResultsRef.current) {
      fetchSearchResultsRef?.current?.cancel();
    }
    fetchSearchResultsRef.current = debounce(async () => {
      const res = await axiosApiInstance.request({
        method: 'get',
        url: '/correspondants',
        params: { limit: 100, page: 1, correspondent_name: inputValue },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      callback(res?.data?.correspondants?.map((val) => ({
        ...val,
        label: val?.nom,
        value: val?.id,
      })));
    }, 500, { maxWait: 700, trailing: true, leading: false });
    fetchSearchResultsRef.current();
  };

  const onSubmitBulkHandler = async () => {
    const data = fieldsData
      .map((val) => ({ ...val, correspondant_id: val?.correspondant_id?.id, user_id: userId }))
      .filter((val) => !!val.correspondant_id && val.date_correspondance);

    if (data.length > 0) {
      setLoading(true);
      await dispatch(bulkCreate({ courriers: data }));
      setLoading(false);
      await dispatch(getAll({ limit: pagination.per_page, page: 1 }));
      setFieldsData([cloneDeep(INITIAL_FIELDS)]);
    }
  };

  return (
    <Container fluid>
      {fieldsData.map((field, index) => (
        <Row key={`index-${index}`}>
          <Col>
            <StyledFormGroup controlId={`correspondant_id-${index}`}>
              <Form.Label>Correspondant</Form.Label>
              <AsyncSelect
                defaultOptions
                cacheOptions
                loadOptions={correspondents}
                placeholder="Choisir Correspondant"
                value={field?.correspondant_id}
                onChange={(data) => onChangeHandler('correspondant_id', index)(data)}
                classNamePrefix="correspondant"
              />
            </StyledFormGroup>
          </Col>
          <Col>
            <StyledDatePicker
              value={field?.date_correspondance}
              placeholder="Choisir une date"
              label="Date Correspondance"
              onDateChange={onChangeHandler('date_correspondance', index)}
            />
          </Col>
          <Col>
            <StyledFormGroup controlId={`reference-${index}`}>
              <Form.Label>Référence</Form.Label>
              <Form.Control
                name="reference"
                placeholder="Saisir une référence"
                value={field?.reference}
                type="text"
                onChange={({ target: { value } }) => onChangeHandler('reference', index)(value)}
              />
            </StyledFormGroup>
          </Col>
        </Row>
      ))}
      <StyleButtonWrapper>
        {fieldsData.length > 1 && (
          <DeleteButton
            variant="light"
            type="button"
            onClick={onRemoveLastRowHandler}
            disabled={isLoading}
          >
            Supprimer
          </DeleteButton>
        )}
        <Button
          variant="light"
          type="button"
          onClick={onAddMoreRowHandler}
          disabled={isLoading}
        >
          Créer plus
        </Button>
        <SubmitButton
          variant="primary"
          type="button"
          onClick={onSubmitBulkHandler}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </>
          ) : 'Enregistrer'}
        </SubmitButton>
      </StyleButtonWrapper>
    </Container>
  );
}

export default RegisterArriveBulkForm;
