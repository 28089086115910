import { NOTIFICATIONS } from './actions';

export const getNotifications = (id) => ({
  client: 'api',
  request: { method: 'get', url: `/users/${id}/notifications` },
  type: NOTIFICATIONS,
});

export const clearAllNotifications = (id) => ({
  client: 'api',
  request: { method: 'delete', url: `/users/${id}/notifications/delete_all` },
  type: NOTIFICATIONS,
});
