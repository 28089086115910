import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FormButton from '../Button';

const StyledModalTitle = styled(Modal.Title)`
  color: #1A202C;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`;

const StyledDescription = styled.div`
  padding-bottom: 34px;
  padding-top: 34px;

  p {
    color: #1A202C;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`;

function ForwardModal({
  description,
  isLoading,
  isVisible,
  onClose,
  onSubmit,
  title,
}) {
  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      centered
      backdrop="static"
    >
      {title && (
        <Modal.Header>
          <StyledModalTitle>{title}</StyledModalTitle>
        </Modal.Header>
      )}
      <Modal.Body>
        {description && (<StyledDescription><p>{description}</p></StyledDescription>)}
        <div>
          <FormButton
            title="Fermer"
            onClick={onClose}
            isLoading={isLoading}
          />
          <FormButton
            title="Transférer"
            variant="primary"
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ForwardModal.propTypes = {
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

ForwardModal.defaultProps = {
  description: null,
  isLoading: false,
  isVisible: false,
  onClose: () => { },
  onSubmit: () => { },
  title: null,
};

export default ForwardModal;
