import get from 'lodash/get';
import { REDUCERS_NAME } from '../../constants';

const reducerName = REDUCERS_NAME.CORRESPONDENTS;

export const correspondent = (id) => (state) => get(state, `${reducerName}.data.[${id}]`);
export const correspondents = (state) => get(state, `${reducerName}.data`, {});
export const createLoader = (state) => get(state, `${reducerName}.createLoader`);
export const error = (state) => get(state, `${reducerName}.error`);
export const loader = (state) => get(state, `${reducerName}.loader`);
export const updateLoader = (state) => get(state, `${reducerName}.updateLoader`);
export const pagination = (state) => get(state, `${reducerName}.pagination`);
