import { useEffect, useRef, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import ClassificationRegisterExpandableRows from '../../../components/ExpandableRows/classification-register';

import { getAllDeparture } from '../../../redux/reducers/mailClassification/actionCreators';
import { departureGetAll, departureLoader, departurePagination } from '../../../redux/reducers/mailClassification/selectors';
import { WORKFLOW_STATE_KEY } from '../../../constants';

const StyledCard = styled.div`
  .table-header, .item {
    display: flex;
  }

  .table-header, .item {
    p {
      flex: 1;
      width: 25%;
    }
  }

  .rdt_Pagination {
    border: unset;
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }
`;

const TableHeader = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    color: #3D5278;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
  }

  .actions {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      align-items: center;
      background: #7AC943;
      border-radius: 34px;
      color: #FFFFFF;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 24px;
      margin-left: 16px;
      width: 209px;

      &:hover, &:active, &:focus {
        background-color: #7AC943 !important;
      }

      svg {
        color: #FFFFFF;
      }
    }

    input {
      background: #FFFFFF;
      border-radius: 70px;
      border: 1px solid rgba(195, 212, 233, 0.4);
      color: #90A3BF;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      width: 254px;
    }
  }
`;

const StyledDataTable = styled(DataTable)`
  border-radius: 10px;
  border: 1px solid rgba(195, 212, 233, 0.5);
  margin-top: 16px;

  .rdt_TableCell, .rdt_TableCol {
    color: #90A3BF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 21px;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    font-weight: 700;
  }

  .rdt_TableHeadRow {
    background-color: #81CC4D;
  }

  .rdt_TableHeadRow .rdt_TableCol{
    color: #fff;
  }
`;

function OutgoingMail() {
  const dispatch = useDispatch();
  const fetchSearchResultsRef = useRef();

  const isLoading = useSelector(departureLoader);
  const pagination = useSelector(departurePagination);
  const tableData = Object.values((useSelector(departureGetAll)));

  const [filterText, setFilterText] = useState('');

  const stringWithTooltip = (val) => (
    <OverlayTrigger overlay={(<Tooltip>{val}</Tooltip>)}>
      <div>{val}</div>
    </OverlayTrigger>
  );

  const columns = [
    {
      name: 'Sequence',
      selector: (row) => row.sequence,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Correspondant',
      selector: (row) => stringWithTooltip(row.correspondant_name),
      sortable: true,
      width: '160px',
    },
    {
      name: 'Date Edition',
      selector: (row) => row.date_edition,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Objet',
      selector: (row) => stringWithTooltip(row.objet),
      sortable: true,
      width: 'unset',
      wrap: true,
    },
    {
      name: 'Reference',
      selector: (row) => stringWithTooltip(row.reference),
      sortable: true,
      width: 'unset',
      wrap: true,
    },
    {
      name: 'Statut',
      selector: (row) => stringWithTooltip(WORKFLOW_STATE_KEY[row.workflow_state?.toLowerCase()]),
      sortable: true,
      width: '160px',
    },
  ];

  const onInputChangeHandler = (val) => {
    setFilterText(val);
    if (fetchSearchResultsRef.current) {
      fetchSearchResultsRef?.current?.cancel();
    }
    fetchSearchResultsRef.current = debounce(async () => {
      await dispatch(getAllDeparture({ limit: pagination.per_page, page: 1, courrier_object: val }));
    }, 500, { maxWait: 700, trailing: true, leading: false });
    fetchSearchResultsRef.current();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(getAllDeparture({ limit: newPerPage, page, courrier_object: filterText }));
  };

  const handlePageChange = (page) => {
    dispatch(getAllDeparture({ limit: pagination?.per_page, page, courrier_object: filterText }));
  };

  useEffect(() => {
    dispatch(getAllDeparture({ limit: pagination?.per_page, page: 1 }));
  }, []);

  return (
    <div className="card">
      <div className="card-header border-1">
        <h3 className="card-title">
          Registre Départ
        </h3>
      </div>
      <StyledCard className="card-body py-3">
        <TableHeader>
          <h4>Courriers de départ</h4>
          <div className="actions">
            <Form.Control
              type="search"
              placeholder="Recherche par objet"
              value={filterText}
              onChange={({ target: { value } }) => onInputChangeHandler(value)}
            />
          </div>
        </TableHeader>
        <StyledDataTable
          columns={isLoading ? [] : columns}
          data={isLoading ? [] : tableData.map((data) => ({ ...data, is_confidential: data.is_confidential ? 'yes' : 'no' }))}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={pagination?.total_objects || 0}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={<p style={{ margin: '0', padding: 24 }}>Il n&apos;y a aucun enregistrement à afficher</p>}
          expandableRows
          expandableRowsComponent={ClassificationRegisterExpandableRows}
        />
      </StyledCard>
    </div>
  );
}

export default OutgoingMail;
