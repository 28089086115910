import { createReducer } from '../../utility';
import { ERROR, LOADED, LOADING } from '../../middleware/actions';
import { LOGOUT } from '../authentication/actions';
import { NOTIFICATIONS } from './actions';

const initialState = {
  data: null,
  error: null,
  loader: false,
};

const reducers = {
  [NOTIFICATIONS + LOADING](state) {
    return {
      ...state,
      loader: true,
      error: null,
    };
  },
  [NOTIFICATIONS + LOADED](state, payload) {
    return {
      ...state,
      loader: false,
      error: null,
      data: payload?.result?.notifications,
    };
  },
  [NOTIFICATIONS + ERROR](state, payload) {
    return {
      ...state,
      loader: false,
      error: payload.result,
    };
  },

  // Reset reducers
  [LOGOUT]() {
    return initialState;
  },
  [LOGOUT + LOADED]() {
    return initialState;
  },
};

export default createReducer(reducers, initialState);
