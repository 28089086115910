import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import styled from 'styled-components'

import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

import { getNotifications } from '../../../../redux/reducers/notifications/actionCreators'
import { selectCurrentUserId } from '../../../../redux/reducers/authentication/selectors'
import { selectUser } from '../../../../redux/reducers/user/selectors'

const BellButton = styled.div`
  &&& {
    border-radius: 50%;
    border: 1px solid rgba(195, 212, 233, 0.4) !important;
    height: 44px !important;
    width: 44px !important;
  }
`;

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const TopBar = () => {

  const dispatch = useDispatch()

  const currentUserId = useSelector(selectCurrentUserId)
  const currentUser = useSelector(selectUser(currentUserId))

  let userImage = toAbsoluteUrl('/media/avatars/300-1.jpg')
  if (currentUser?.image) {
    userImage = currentUser?.image
  }

  useEffect(() => {
    dispatch(getNotifications(currentUserId))
  }, [])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <BellButton
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/bell.svg'
            className={toolbarButtonIconSizeClass}
          />
        </BellButton>
        <HeaderNotificationsMenu />
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <BellButton
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/settings.svg'
            className={toolbarButtonIconSizeClass}
          />
        </BellButton>
        <HeaderUserMenu />
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
        >
          <img src={userImage} alt='user' referrerPolicy="no-referrer" className="rounded-circle" />
        </div>
      </div>
    </div>
  )
}

export { TopBar }
