import get from 'lodash/get';
import { createSelector } from 'reselect';
import { REDUCERS_NAME } from '../../constants';

const reducerName = REDUCERS_NAME.USER;

export const selectError = (state) => get(state, `${reducerName}.all.error`);
export const selectLoader = (state) => get(state, `${reducerName}.all.loader`);
export const selectUser = (id) => (state) => get(state, `${reducerName}.all.data.[${id}]`);
export const selectUserRole = (id) => createSelector(selectUser(id), (data) => data?.role_id);
export const selectUsers = (state) => get(state, `${reducerName}.all.data`);

export const selectUserByRoleError = (state) => get(state, `${reducerName}.byRole.error`);
export const selectUserByRoleLoader = (state) => get(state, `${reducerName}.byRole.loader`);
export const selectUserByRoleData = (state) => get(state, `${reducerName}.byRole.data`, {});
