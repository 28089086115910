import { COURRIERS_ARRIVEE, CREATE_COURRIERS_ARRIVEE, DELETE_COURRIERS_ARRIVEE, UPDATE_COURRIERS_ARRIVEE } from './actions';

export const getAll = (params) => ({
  client: 'api',
  request: { method: 'get', url: '/courrier_arrivees', params },
  type: COURRIERS_ARRIVEE,
});

export const create = (data) => ({
  client: 'api',
  request: { method: 'post', url: '/courrier_arrivees', data },
  type: CREATE_COURRIERS_ARRIVEE,
});

export const bulkCreate = (data) => ({
  client: 'api',
  request: { method: 'post', url: '/courrier_arrivees/bulk_create', data },
  type: CREATE_COURRIERS_ARRIVEE,
});

export const updateById = (data) => ({
  client: 'api',
  request: { method: 'put', url: '/courrier_arrivees/update', data },
  type: UPDATE_COURRIERS_ARRIVEE,
});

export const deleteById = (data) => ({
  client: 'api',
  request: { method: 'delete', url: '/courrier_arrivees/delete', data },
  type: DELETE_COURRIERS_ARRIVEE,
});
