export const REDUCERS_NAME = {
  ACTIONS: 'actions',
  AUTHENTICATION: 'authentication',
  CORRESPONDENTS: 'correspondents',
  COURRIERS_ARRIVEE: 'courriersArrivee',
  COURRIERS_DEPARTURE: 'courriersDeparture',
  DASHBOARD: 'dashboard',
  MAIL_CLASSIFICATION: 'mailClassification',
  NOTIFICATIONS: 'notifications',
  USER: 'user',
};

export default {
  REDUCERS_NAME,
};
