import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import styled from 'styled-components'

import { checkIsActive, KTSVG } from '../../../helpers'
import {useLayout} from '../../core'

const StyledTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #90A3BF;
`;

const Item = styled.div`
  &&&{
    margin-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .menu-icon {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  a {
    height: 54px;
  }

  a:hover, .active {
    background: #81CC4D;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 19px;
  }

  &&&&&&& {
    a:hover, .active {
      .menu-title {
        color: #FFFFFF;
      }
      .menu-icon {
        path {
          stroke: #FFFFFF;
        }
      }
    }
  }
`;

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: React.ReactNode
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  return (
    <Item className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <KTSVG path={icon} className='menu-icon' />
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <StyledTitle className='menu-title'>{title}</StyledTitle>
      </Link>
      {children}
    </Item>
  )
}

export {AsideMenuItem}
