import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
  && {
    ${({ variant }) => variant === 'primary' && css`
      background-color: #7AC943;
      border: unset !important;
      color: #FFFFFF;

      &&& {
        &:hover, &:active, &:focus {
          background-color: #7AC943 !important;
        }
      }
    `}

    ${({ variant }) => variant === 'danger' && css`
      background-color: #FF4423;
      border: unset !important;
      color: #FFFFFF;

      &&& {
        &:hover, &:active, &:focus {
          background-color: #FF4423 !important;
        }
      }
    `}
  }
`;

function ActionButton({ onClick, icon, title, variant }) {
  return (
    <StyledButton
      onClick={onClick}
      variant={variant}
      size="sm"
    >
      {icon && <i className={`fa fa-${icon}`} />}
      {title && <span>{title}</span>}
    </StyledButton>
  );
}

ActionButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

ActionButton.defaultProps = {
  icon: null,
  title: null,
  onClick: () => { },
  variant: 'light',
};

export default ActionButton;
