import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormButton from '../../../../components/Button'

import { logout } from '../../../../redux/reducers/authentication/actionCreators'
import { selectCurrentUserId } from '../../../../redux/reducers/authentication/selectors'
import { selectUser, selectUserRole } from '../../../../redux/reducers/user/selectors'

import { USER_ROLES } from '../../../../constants'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu = () => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null)

  const currentUserId = useSelector(selectCurrentUserId)
  const currentUser = useSelector(selectUser(currentUserId))
  const userRole = useSelector(selectUserRole(currentUserId));

  /* @ts-ignore */
  const role = USER_ROLES[userRole] || null;

  let userImage = toAbsoluteUrl('/media/avatars/300-1.jpg')
  if (currentUser?.image) {
    userImage = currentUser?.image
  }

  const onClose = () => {
    ref.current?.classList.remove("show")
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold w-600px'
      data-kt-menu='true'
      ref={ref}
    >
      <h2 className="p-5">User Profile</h2>
      <div className='menu-item px-3 border-top border-bottom'>
        <div className='menu-content d-flex align-items-center px-3 p-10'>
          <div className='me-5 w-140px'>
            <img alt='user' src={userImage} className="rounded-circle" />
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-2'>
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
            <div className='d-flex align-items-center py-3'>
              <span>{role}</span>
            </div>
            <div className='d-flex align-items-center'>
              <span className='fa fa-envelope me-2'></span>
              <a target={"_blank"} href={`mailto:${currentUser?.email}`}>{currentUser?.email}</a>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end p-5'>
        <FormButton variant={'danger'} title={`Sign Out`} onClick={() => dispatch(logout())}></FormButton>
        <FormButton variant={'primary'} title={`Close`} onClick={onClose}></FormButton>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
