import { JSXElementConstructor, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectIsLoggedIn } from '../redux/reducers/authentication/selectors';

function withAuth(WrappedComponent: JSXElementConstructor<any>) {
  // eslint-disable-next-line func-names
  return function () {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!isLoggedIn && location.pathname !== '/auth') {
        navigate('/auth');
      }
    }, [isLoggedIn]);

    return <WrappedComponent />;
  };
}

export default withAuth;
