import { Button, Spinner } from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useGoogleLogin} from '@react-oauth/google'
import styled from 'styled-components'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {login} from '../../../../redux/reducers/authentication/actionCreators'
import {selectIsLoggedIn} from '../../../../redux/reducers/authentication/selectors'
import { useState } from 'react'

const GoogleLoginButton = styled(Button)`
  border-radius: 19px;
  border: 1px solid rgba(195, 212, 233, 0.5);
  color: #717171;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 80%;

  @media only screen and (min-width: 768px) {
    max-width: 440px;
    width: 100%;
  }
`;

export function Login() {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(selectIsLoggedIn)

  const [isLoading, setLoading] = useState(false)

  // eslint-disable-next-line camelcase
  const onLoginSuccess = async ({access_token}: any) => {
    setLoading(true)
    if (!isLoggedIn) {
      await dispatch(login({access_token}))
    }
    setLoading(false)
  }

  const onLoginHandler = useGoogleLogin({
    onSuccess: onLoginSuccess,
    // eslint-disable-next-line no-console
    onError: console.log,
  })

  return (
    <GoogleLoginButton
      onClick={() => onLoginHandler()}
      type='button'
      variant="light"
    >
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-20px me-3'
            />
          Sign In with Google
        </>
      )}
    </GoogleLoginButton>
  )
}
