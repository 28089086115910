import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider } from './app/modules/auth';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import { useStore } from './redux/configureStore';

import config from './config';

import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';

const queryClient = new QueryClient();

function App() {
  const store = useStore({});

  const persistor = persistStore(store, {}, () => {
    persistor.persist();
  });
  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <Provider store={store}>
        <PersistGate loading={<p>Loading...</p>} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </MetronicI18nProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
