import { ACTIONS } from './actions';
import { ERROR, LOADED, LOADING } from '../../middleware/actions';
import { LOGOUT } from '../authentication/actions';

import { createReducer } from '../../utility';

const initialState = {
  data: {},
  error: '',
  loader: false,
};

const reducers = {
  [ACTIONS + LOADING]() {
    return {
      ...initialState,
      loader: true,
    };
  },
  [ACTIONS + LOADED](state, payload) {
    const data = payload?.result?.reduce((result, entity) => ({ ...result, [entity.id]: entity }), {});
    return {
      ...state,
      loader: false,
      error: '',
      data: { ...state.data, ...data },
    };
  },
  [ACTIONS + ERROR](state, payload) {
    return {
      ...state,
      loader: false,
      error: payload.result,
    };
  },

  // Reset reducers
  [LOGOUT]() {
    return initialState;
  },
  [LOGOUT + LOADED]() {
    return initialState;
  },
};

export default createReducer(reducers, initialState);
