/* eslint-disable */
import get from 'lodash/get'
import {LOADING, LOADED, ERROR} from './actions'
import {selectAuthToken, selectRefreshToken} from '../reducers/authentication/selectors'
import {errorMessage} from '../../config/clients'
import {refreshToken} from '../reducers/authentication/actionCreators'

const apiClients = (clients) => (store) => (next) => async (action) => {
  const makeAction = (status, data) => {
    const newAction = {...action, type: action.type + status, ...data}
    delete newAction.request
    return newAction
  }

  const addTokenToRequest = (state, request) => {
    const authToken = selectAuthToken(state)
    return authToken
      ? {
          ...request,
          headers: {
            ...(request.headers || null),
            Authorization: `Bearer ${authToken}`,
          },
        }
      : request
  }

  if (!action || !action.request) {
    return next(action)
  }

  const clientName = action.client || 'default'
  if (!clients[clientName]) {
    throw new Error(`Client with name "${clientName}" has not been defined in middleware`)
  }

  next(makeAction(LOADING, false))

  const refreshTokenKey = selectRefreshToken(store.getState())
  const authToken = selectAuthToken(store.getState())

  const request = addTokenToRequest(store.getState(), action.request)

  const axiosApiInstance = clients[clientName].client

  const successAction = (result) => {
    let payload = { result: result.data, originalPayload: action.request.data || action.request.params }
    const error = get(result, 'error')
    if (error) {
      payload = {
        ...payload,
        error: {
          result: error,
        },
      }
    }
    next(makeAction(LOADED, {payload}))
    if (action.callback) {
      action.callback(store, payload)
    }
  }

  const errorAction = (data) => {
    const errorData = {}
    if (data?.response?.data?.message) {
      errorData.message = data?.response?.data?.message
    } else if (data?.response?.data?.error) {
      errorData.message = data?.response?.data?.error
    }
    const payload = {result: {error: errorMessage(errorData)}, originalPayload: action.request.data}
    next(makeAction(ERROR, {payload}))
    if (action.callback) {
      action.callback(store, payload)
    }
  }

  return axiosApiInstance.request(request).then(successAction, async (error) => {
    const originalConfig = error.config
    if (
      originalConfig?.url !== '/auth/sign_in' &&
      error?.response?.status === 401 &&
      refreshTokenKey &&
      authToken
    ) {
      try {
        const response = await axiosApiInstance.request({
          method: 'post',
          url: '/users/tokens',
          headers: {
            'Refresh-Token': refreshTokenKey,
            Authorization: `Bearer ${authToken}`,
          },
        })
        const config = {
          ...originalConfig,
          headers: {...originalConfig.headers, Authorization: `Bearer ${response?.data?.token}`},
        }
        store.dispatch(refreshToken(response?.data))
        axiosApiInstance.request(config).then(successAction, errorAction)
      } catch (error) {
        console.log({error})
      }
    }
    errorAction(error)
  })
}

export default apiClients
