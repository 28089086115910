import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActionButton from '../ActionButton';
import PreviewMailFileModal from '../PreviewMailFileModal';
import PreviewMailModal from '../PreviewMailModal';

const ActionsRow = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px;

  button {
    margin-right: 8px;
  }
`;

function ClassificationRegister({ data }) {
  const [isShowFile, setShowFile] = useState(false);
  const [isShowPreview, setShowPreview] = useState(false);

  const showFilePreviewHandler = () => {
    setShowFile(true);
  };

  const previewMailHandler = () => {
    setShowPreview(true);
  };

  const showFilePreview = (
    <ActionButton
      onClick={() => showFilePreviewHandler(data)}
      icon="file"
      title="Aperçu fichier"
    />
  );

  const previewMail = (
    <ActionButton
      onClick={() => previewMailHandler(data)}
      icon="eye"
      title="Détails"
    />
  );

  const preview = [
    { label: 'Créé le', key: 'created_at' },
    { label: 'Date correspondance', key: 'date_correspondance' },
    { label: 'Date édition', key: 'date_edition' },
    { label: 'mis à jour le', key: 'updated_at' },
    { label: 'Priorité', key: 'priorite' },
    { label: 'Référence', key: 'reference' },
    { label: 'Fichier', key: 'scan' },
    { label: 'Séquence', key: 'sequence' },
    { label: 'Statut', key: 'workflow_state' },
    { label: 'Actions demandées', key: 'actions' },
    { label: 'Imputations', key: 'users' },
    { label: 'Commentaire', key: 'commentaire' },
  ];

  return (
    <ActionsRow>
      {previewMail}
      {data.scan && showFilePreview}

      <PreviewMailModal
        data={data}
        isVisible={isShowPreview}
        onClose={() => setShowPreview(false)}
        previewKeysArray={preview}
        title="Aperçu courrier"
      />

      <PreviewMailFileModal
        data={data}
        isVisible={isShowFile}
        onClose={() => setShowFile(false)}
        title="Prévisualisation Courrier"
      />
    </ActionsRow>
  );
}

ClassificationRegister.propTypes = {
  data: PropTypes.shape({
    correspondant_id: PropTypes.number,
    id: PropTypes.string,
    scan: PropTypes.string,
    user_id: PropTypes.number,
    workflow_state: PropTypes.string,
  }),
};

ClassificationRegister.defaultProps = {
  data: {},
};

export default ClassificationRegister;
