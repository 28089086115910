import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledModalTitle = styled(Modal.Title)`
  color: #1A202C;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`;

function PreviewMailFileModal({
  data,
  isVisible,
  onClose,
  title,
}) {
  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      centered
    >
      <Modal.Header closeButton>
        {title && (<StyledModalTitle>{title}</StyledModalTitle>)}
      </Modal.Header>
      <Modal.Body>
        <embed src={`https://${data.scan}`} style={{ width: '100%', height: '500px' }} />
      </Modal.Body>
    </Modal>
  );
}

PreviewMailFileModal.propTypes = {
  data: PropTypes.shape({
    scan: PropTypes.string,
  }),
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

PreviewMailFileModal.defaultProps = {
  data: {},
  isVisible: false,
  onClose: () => { },
  title: null,
};

export default PreviewMailFileModal;
